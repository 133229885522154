import React from 'react'
import { graphql } from 'gatsby'

import '@wordpress/block-library/build-style/style.css'
import '@wordpress/block-library/build-style/theme.css'

import GeneralPageLayout from './../components/Layouts/GeneralPage'
import SectionContainer from '../components/UI/Containers/SectionContainer'
import Seo from './../components/Header/SEO/Seo'

import ArticleMainList from '../components/News/Lists/ArticleMainList'

export default function articleListTemplate({
  location,
  data,
  data: { list },
  pageContext,
}) {
  const pageInfo = {
    pageType: 'interior',
    title1: 'Precise',
    title2: 'News',
    breadcrumbs: [''],
    ctaButton: false,
    ctaButtonData: {
      text: '',
      url: '',
    },
  }

  return (
    <GeneralPageLayout pageData={pageInfo}>
      {/* <Seo title={post.title} description={post.excerpt} /> */}
      <div className="content">
        <SectionContainer id="" color="white" type="contained">
          {list ? (
            <ArticleMainList
              pageContext={pageContext}
              list={list}
              location={location}
            />
          ) : (
            <div>Loading...</div>
          )}
        </SectionContainer>
      </div>
    </GeneralPageLayout>
  )
}

export const articleListQuery = graphql`
  query articleListQuery($skip: Int!, $limit: Int!) {
    list: allWpPost(
      limit: $limit
      skip: $skip
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          date(formatString: "MMMM DD, YYYY")
          id
          uri
          title
          excerpt
          featuredImage {
            node {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    backgroundColor: "#DADEDF"
                  )
                }
              }
            }
          }
          author {
            node {
              name
            }
          }
          categories {
            nodes {
              name
            }
          }
        }
      }
    }
  }
`

// featuredImage {
//     node {
//       altText
//       localFile {
//         childImageSharp {
//           gatsbyImageData(
//             quality: 100
//             placeholder: TRACED_SVG
//             layout: FULL_WIDTH
//           )
//         }
//       }
//     }
//   }
